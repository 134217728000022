// https://github.com/emotion-js/emotion/issues/1306
/** @jsx jsx */
import { jsx } from '@emotion/core'

import React from "react"
import { Link, graphql } from "gatsby"
import SEO from '../components/seo'
import Img from "gatsby-image"
import { RichText, Elements } from "prismic-reactjs/src"
import { linkResolver } from "../utils/linkResolver"
import Layout from "../components/layout"
import { css } from "@emotion/core"
// import { Default } from "../utils/responsive"
import 'minireset.css'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });	
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function(type, element, content, children, key) {
  var props = {};
  
  switch(type) {
    // Add a class to paragraph elements
    // case Elements.paragraph:
    //   props = {className: 'paragraph-class'};
    //   return React.createElement('p', propsWithUniqueKey(props, key), children);
	
    // Don't wrap images in a <p> tag
    case Elements.image: 
      props = { src: element.url , alt: element.alt || '' };
      return React.createElement('img', propsWithUniqueKey(props, key));	
	
    // Return null to stick with the default behavior
    default: 
      return null;
  }
};

export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismic {
      allPosts(uid: $uid) {
        edges {
          node {
            title
            post_image
            post_imageSharp{
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date
            category {
              ... on PRISMIC_Category {
                name
                _meta {
                  id
                  uid
                  type
                }
              }
            }

            body {
              ... on PRISMIC_PostBodyText {
                type
                label
                primary {
                  text
                }
              }
              ... on PRISMIC_PostBodyImage {
                type
                primary {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 860) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }

            _meta {
              tags
              firstPublicationDate
            }
            post_embed
          }
        }
      }
    }
  }
`
const PrismicSlices = ({ slices }) => {
  
  if (slices === undefined || slices === null) { return null }

  return slices.map(s => {
    switch (s.type) {
      // These are the API IDs of the slices
      case 'text':
        return <RichText render={s.primary.text} htmlSerializer={htmlSerializer} />
      case 'image':
        console.log()
        if (s.primary.imageSharp === undefined || s.primary.imageSharp === null) {
          return <img src={s.primary.image} />
        }
        return <Img fluid={s.primary.imageSharp.childImageSharp.fluid} />
      default:
        return null
    }
  })

}

const Post = ({ data }) => {
// export default ({ data }) => {

  const doc = data.prismic.allPosts.edges.slice(0, 1).pop()
  if (!doc) return null

  return (

    // doc.node.body === null ? null : <RichText render={doc.node.body[0].primary.text} htmlSerializer={htmlSerializer} />
      // doc.node.post_imageSharp === null ? null : <Img fluid={doc.node.post_imageSharp.childImageSharp.fluid} />
    
    <>
    <SEO title={RichText.asText(doc.node.title)} />
    <Layout>
    <div css={css`position: relative; `}>
    { 
      
      // Check if imageSharp exists
      (doc.node.post_imageSharp === undefined || doc.node.post_imageSharp === null) ? 

      // Use normalge image for previews
      doc.node.post_image === null ? null : <img src={doc.node.post_image.url} />
      
      // Gatsby Image for production and published posts
    : <Img fluid={doc.node.post_imageSharp.childImageSharp.fluid} /> }

    { doc.node.post_embed === null ? null :

         <div css={css`position: absolute; top:0; left:0; width: 100%; height: 100%; display: flex;   align-items: center;   justify-content: center; 

      iframe{ width: 100%; height: 100%; max-width: 1140px; max-height: 641px; }
     
      `} dangerouslySetInnerHTML={ {__html: doc.node.post_embed.html} }>
      </div>
      

    }
     </div>
    <div css={css`  height: 27px;
font-family: 'Cormorant', Serif;
font-size: 17px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: 1.59;
letter-spacing: normal;
text-align: right;
color: rgba(0, 0, 0, 0.57);margin-right:16px;`}>{ doc.node.post_image === null ? null : doc.node.post_image.alt }</div>

      <div  css={css` @media (max-width: 768px) { padding: 0 20px; } margin: 0 auto; max-width: 860px;`}>

        <div css={css`
      margin-top: 51px;
      margin-bottom: 67px;
      width: 100%;
      height: 27px;
      font-family: 'Libre Franklin', Sans-Serif;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.93;
      letter-spacing: 5px;
      text-align: center;
      color: #000000;
      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 1.25em;
        margin: 20px auto;
      }

  `}>{ (doc.node.category === null) ? null : 
    <><Link to={linkResolver(doc.node.category._meta)} css={css`color: #000; text-decoration: none;`}>{doc.node.category.name}</Link> | </> } 
    { dayjs(doc.node.date.substring(0, doc.node.date.length - 14), 'YYYY-MM-DD').format('DD. MMM YYYY') }
    </div>

      <h1 css={css`
  width: 100%;
  font-family: 'Cormorant', Serif;
  font-size: 60px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.03;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
 
  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  `}>{RichText.asText(doc.node.title)}</h1>
      <div css={css`
  font-family: 'Cormorant', Serif;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.52;
  letter-spacing: 0.57px;
  color: #000000;
  .embed{
    margin-bottom: 50px;
    &.embed-instagram .instagram-media{
      margin: 0 auto !important;
    }
    &.embed-youtube {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;

      iframe{
        position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
    }
  }
  .gatsby-image-wrapper{
    margin: 0 auto;
    display: block;
    margin-bottom: 50px;
  }
  p{
    margin-bottom: 50px;
    &.intro{
        font-family: ChronicleDisplay-Roman, Serif;
        font-size: 35px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #000000;
    }
  }

  a{
    color: #f00;
    &:hover{
      color: #000;
    }
  }


  @media (max-width: 768px) {
    font-size: 18px;
    p{
      margin-bottom: 20px;
    }
  }
      `}>{
    
        <PrismicSlices slices={doc.node.body} />
        
        /*
        doc.node.body === null ? null :
        <PrismicSlices slices={doc.node.body} />
      /*<RichText render={doc.node.content} htmlSerializer={htmlSerializer} />*/
      }</div>
      </div>
    </Layout>
    </>
  )
}

Post.query = graphql`
query BlogPostQuery($uid: String) {
  prismic {
    allPosts(uid: $uid) {
      edges {
        node {
          title
          post_image
          body {
            ... on PRISMIC_PostBodyText {
              type
              label
              primary {
                text
              }
            }
            ... on PRISMIC_PostBodyImage {
              type
              primary {
                image
              }
            }
          }
          _meta {
            tags
            firstPublicationDate
          }
          post_embed
          category {
            ... on PRISMIC_Category {
              name
              _meta {
                id
                uid
                type
              }
            }
          }
        }
      }
    }
  }
}
`

export default Post